<script setup lang="ts">
import { MediaMaxBreakpoints } from '@ui/types'
import { ContentFooterNavigationProps } from '../../types'

defineProps<ContentFooterNavigationProps>()

const { isMediaMatch: isSmallView } = useMediaQuery(MediaMaxBreakpoints.SM)
const contentMounted = ref(false)
onMounted(() => {
  contentMounted.value = true
})
</script>
<template>
  <div class="asf-footer__navigation-wrapper">
    <AsfWrapper v-if="!contentMounted && navigations?.length" class="asf-footer__navigation-skeleton">
      <div class="asf-footer__navigation-skeleton__mobile">
        <AsfSkeleton v-for="i in navigations.length" :key="`item-${i}`" :size="['full', '11']" />
      </div>
      <div class="asf-footer__navigation-skeleton__desktop">
        <AsfSkeleton v-for="i in navigations.length" :key="`item-${i}`" class="w-1/3" :size="['full', '44']" />
      </div>
    </AsfWrapper>
    <div v-show="contentMounted" class="asf-footer__navigation">
      <div v-if="isSmallView" class="asf-footer-navigation__accordion-wrapper">
        <AsfAccordion
          v-if="navigations && navigations.length"
          class="asf-footer-navigation__accordion"
          :allow-multiple="false"
        >
          <AsfAccordionItem
            v-for="({ title, content }, index) in navigations"
            :id="title || `${index}`"
            :key="title"
            :label="title || ''"
            class="asf-footer-navigation__accordion-item"
          >
            <AsfList>
              <AsfListItem
                v-for="(link, idx) in content"
                :key="`navigation__link-${idx}`"
                v-e2e="`${title}-${idx}-footer-navigation-link`"
              >
                <ContentLink v-bind="link" class="asf-link-secondary asf-footer-navigation__link" />
              </AsfListItem>
            </AsfList>
          </AsfAccordionItem>
        </AsfAccordion>

        <div v-if="isFindInStore" class="asf-footer-navigation__find-wrapper m-mobile">
          <AsfLink link="#" class="asf-link-secondary asf-footer-navigation__find m-mobile">
            <AsfIcon name="geo" />
            <span>{{ $t('footer.findInStore') }}</span>
          </AsfLink>
        </div>
      </div>

      <AsfWrapper v-else class="asf-footer-navigation__list-wrapper">
        <AsfList v-if="navigations && navigations.length" class="asf-footer-navigation__list">
          <AsfListItem v-for="{ title, content } in navigations" :key="title">
            <AsfHeading tag="h2" appearance="h2" class="asf-footer-navigation__heading">
              <span class="asf-footer-navigation__heading-title"> {{ title }}</span>
            </AsfHeading>
            <AsfList>
              <AsfListItem
                v-for="(link, idx) in content"
                :key="`navigation__link-${idx}`"
                v-e2e="`${title}-${idx}-footer-navigation-link`"
              >
                <ContentLink v-bind="link" class="asf-link-secondary asf-footer-navigation__link" />
              </AsfListItem>
            </AsfList>
          </AsfListItem>
        </AsfList>

        <div v-if="isFindInStore" class="asf-footer-navigation__find-wrapper">
          <AsfLink link="#" class="asf-link-secondary asf-footer-navigation__find">
            <AsfIcon name="geo" />
            <span>{{ $t('footer.findInStore') }}</span>
          </AsfLink>
        </div>
      </AsfWrapper>
    </div>
  </div>
</template>
