<template>
  <div
    v-e2e="'notification'"
    class="asf-notification"
    @mouseover="clearNotificationTimeout"
    @mouseout="hideNotification"
  >
    <AsfAlert :appearance="appearance">
      <slot />
    </AsfAlert>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType } from 'vue'
import { AsfNotificationProps } from '@ui/types'

export default defineComponent({
  name: 'AsfNotification',
  props: {
    /**
     * Defines visual state of the Notification
     * @values info, success, warning, error
     */
    appearance: { type: String as PropType<AsfNotificationProps['appearance']>, default: '' },
    /**
     *  Defines time in seconds while Notification is visible
     */
    displayTime: {
      type: Number as PropType<AsfNotificationProps['displayTime']>,
      default: 4,
      validator: (value: number) => !value || value > 0
    }
  },
  setup(props: AsfNotificationProps, { emit }) {
    let timerId: ReturnType<typeof setTimeout> | undefined
    const timeout = computed(() => (props.displayTime || 1) * 1000)

    const hideNotification = () => {
      timerId = setTimeout(() => emit('notification:hide'), timeout.value)
    }

    const clearNotificationTimeout = () => timerId && clearTimeout(timerId)

    onMounted(hideNotification)
    onBeforeUnmount(clearNotificationTimeout)

    return { hideNotification, clearNotificationTimeout }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Notification/Notification.css';
</style>
