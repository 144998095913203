<template>
  <component :is="link ? 'AsfLink' : 'div'" v-bind="attributes" class="asf-logo">
    <slot>
      <AsfImage :src="image" :alt="`${attributes.title}`" :title="`${attributes.title}`" width="200" height="50" />
    </slot>
  </component>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfLogoProps } from '@ui/types'
import { AsfLink } from '#components'
import image from 'assets/images/logo.svg'

export default defineComponent({
  name: 'AsfLogo',
  components: { AsfLink },
  props: {
    link: { type: [String, Object] as PropType<AsfLogoProps['link']>, default: '' },
    title: { type: String as PropType<AsfLogoProps['title']>, default: '' }
  },
  setup(props: AsfLogoProps) {
    const { t } = useI18n()

    const attributes = computed(() => ({
      link: props.link || null,
      title: props.title || t('logo.link')
    }))

    return { attributes, image }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Logo/Logo.css';
</style>
