import { defineComponent, h, VNode } from 'vue'
import '@components/utilities/styles/NotificationManager.css'

export default defineComponent({
  name: 'AsfNotificationManager',
  setup(_props, { slots }) {
    const { notifications } = useUiNotification()
    return () => {
      const nodes: VNode[] = []

      for (const notification of notifications.value) {
        const scopedSlots = slots.default
        nodes.push(
          h(
            'div',
            {
              key: String(notification.id)
            },
            scopedSlots &&
              scopedSlots({
                ...notification
              })
          )
        )
      }

      const transitionGroup = h(
        'transition-group',
        {
          tag: 'div'
        },
        [nodes]
      )
      const options = {
        class: `asf-notification-manager`
      }

      return h('div', options, [transitionGroup])
    }
  }
})
