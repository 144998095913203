<script setup lang="ts">
import { AsfIconProps } from '@ui/types'
import { ContentFooterSocialProps } from '../../types'

const props = defineProps<ContentFooterSocialProps>()
const iconsList = computed(() => {
  return props.icons?.map((item) => {
    return {
      iconName: item.icon as AsfIconProps['name'],
      image: item.image && item.image[0],
      link: item.link && item.link[0]
    }
  })
})
</script>
<template>
  <div class="asf-set-of-icons">
    <ContentHeading v-bind="title" class="asf-set-of-icons__title">
      <ContentTextField v-bind="description" class="asf-set-of-icons__subtitle" />
    </ContentHeading>
    <AsfList v-if="iconsList" class="asf-set-of-icons__list">
      <AsfListItem v-for="{ link, iconName, image } in iconsList" :key="link ? link.url : 'key'">
        <ContentLink v-if="iconName" v-bind="link" :aria-label="iconName">
          <AsfIcon v-if="iconName" :name="iconName" :id="iconName" size="10" class="asf-set-of-icons__icon" />
          <span class="sr-only">{{ iconName }}</span>
        </ContentLink>
        <ContentLink v-else-if="image && image.asset && link" v-bind="link">
          <AsfImage
            :src="getImagePath(image)"
            :alt="link.text"
            :title="link.text"
            :width="image.width"
            :height="image.height"
          />
        </ContentLink>
        <span v-else />
      </AsfListItem>
    </AsfList>
  </div>
</template>
